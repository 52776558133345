<template>
	<v-row class="ma-0">
		<v-col cols="12" class="d-flex flex-column justify-center align-center">
			<!-- <v-alert type="error" class="text-uppercase">
				Login suspended until the award ceremony!
			</v-alert> -->

			<v-card outlined class="pa-6" width="400">
				<v-card-text>
					<v-img
						:src="
							require('../assets/images/sdc_logo_landscape.png')
						"
						width="180"
					>
					</v-img>
					<div class="text-h5 font-weight-medium mt-3">Sign in</div>
					to continue your SDC journey
					<v-form>
						<v-text-field
							dense
							class="mb-6 mt-12"
							label="Username"
							v-model="userinfo.username"
						>
						</v-text-field>
						<v-text-field
							dense
							class="mb-6 mt-12"
							label="Password"
							v-model="userinfo.password"
							:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
							:type="show ? 'text' : 'password'"
							name="input-10-1"
							@click:append="show = !show"
						>
						</v-text-field>
						<v-row>
							<v-btn
								color="primary"
								text
								@click.native.prevent="submit"
							>
								Sign in
							</v-btn>
							<v-btn color="primary" text @click="clear">
								Clear
							</v-btn>
						</v-row>
					</v-form>
                    <div class="text-caption mt-12">
                        Solar Decathlon China provides login information to
                        authorized accounts only.
                    </div>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {
			userinfo: {
				username: "",
				password: "",
			},
			show: false,
		};
	},

	created() {},

	watch: {},

	components: {},

	computed: {},

	methods: {
		submit() {
			this.$store
				.dispatch("Login", this.userinfo)
				.then(() => {
					this.$router.push({ path: "/dashboard" });
					this.$store.dispatch("GetTeamList");
				})
				.catch(() => {});
		},
		clear() {
			this.userinfo.username = "";
			this.userinfo.password = "";
		},
	},
	async mounted() {},
};
</script>

<style scoped>
</style>